<template>
  <div class="DocTemplate">
    <div @click="back" class="backPreviousPage f-c"><i class="el-icon-d-arrow-left"></i>上一页</div>
    <div class="f-w-b">
      <NavigationBar></NavigationBar>
      <div class="main">
        <div class="banner">
          {{ enterpriseStateInfo.enterpriseName }}
        </div>
        <div class="flex mb-30" style="border: 1px solid #F6F6F6;width: 99.8%; border-radius: 5px;">
          <div @click="handleClick(item)" :style="{ borderRadius: index == 0 ? '5px 0 0 5px' : '' }" class="tabs"
            :class="templateType == item.name ? 'selectTabs' : 'NoselectTabs'" v-for="(item, index) of tabsOptions"
            :key="index">{{ item.label }}</div>
        </div>
        <div class="flex">
          <div class="mr-30 mt-15 w--15">企业logo</div>
          <el-image class="wh-150" :src="TemplateForm.logoImage ? TemplateForm.logoImage : require('@/assets/logo2.png')"
            fit="contain">
          </el-image>
        </div>
        <div class="f-c-b mt-30">
          <div class="w--15">公司抬头</div>
          <div class="modifyNote w--80">{{ TemplateForm.enterpriseName }}</div>
        </div>
        <div class="f-c-b mt-30">
          <div class="w--15">{{ editLabelType }}</div>
          <div class="modifyNote w--80">{{ TemplateForm.enterpriseInfo }}</div>
        </div>
        <div class="f-c-b mt-30">
          <div class="w--15">单据备注</div>
          <div class="modifyNote w--80">{{ TemplateForm.enterpriseNote }}</div>
        </div>
        <div class="f-c-c w--100 mt-30" v-show="enterpriseStateInfo.userPermission.f_s == 'W'">
          <div @click="handleEdit" class="btn">编辑</div>
        </div>
      </div>

    </div>

    <!-- <el-tabs type="border-card" v-model="templateType" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) of tabsOptions" :key="index" :label="item.label" :name="item.name">
        <el-form :model="TemplateForm" label-width="150px">
          <el-form-item v-for="(item, index) of FormArr" :label="item.label ? item.label : editLabelType"
            :prop="item.prop" :key="index">
            <el-image v-if="item.prop == 'logoImage'" class="wh-150"
              :src="TemplateForm.logoImage ? TemplateForm.logoImage : require('@/assets/logo1.png')" fit="contain">
            </el-image>
            <ChineseCharactersInput v-else-if="item.prop == 'enterpriseName'" :modelValue="TemplateForm[item.prop]"
              :placeholderValue="item.placeholder" :maxlength="'30'" @getValue="getValue" :type="item.prop"
              :disabledValue="true">
            </ChineseCharactersInput>
            <TextArea v-else :placeholderValue="item.placeholder" :modelValue="TemplateForm[item.prop]"
              :disabledValue="true"></TextArea>
          </el-form-item>
          <el-form-item class="btns" v-if="enterpriseStateInfo.userPermission.f_s == 'W'">
            <el-button type="primary" round @click="handleEdit">编辑</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs> -->

    <Dialog title="模板修改" width="1280px" :visible="dialogShow" @closeDialog="closeDialog" class="temp">
      <el-form ref="editForm" :model="editForm" :rules="editRules" label-width="150px">
        <el-form-item v-for="(item, index) of FormArr" :label="item.label ? item.label : editLabelType" :prop="item.prop"
          :key="index">
          <div class="f-c" v-if="item.prop == 'logoImage'">
            <UploadOtherImage :imageUrl="editForm.logoImage" :disabled="false" :imageDiff="''" @FormData="FormData"
              @deleteUploadImage="deleteUploadImage">
            </UploadOtherImage>
          </div>
          <ChineseCharactersInput v-else-if="item.prop == 'enterpriseName'" :modelValue="editForm[item.prop]"
            :placeholderValue="item.placeholder" :maxlength="'30'" @getValue="getValue" :type="item.prop">
          </ChineseCharactersInput>
          <TextArea class="fs-18" v-else :placeholderValue="item.placeholder" :modelValue="editForm[item.prop]" :type="item.prop"
            @getTextAreaValue="getTextAreaValue" :maxlengthValue="300"></TextArea>
        </el-form-item>
        <div class="w--100 f-c-c">
          <div class="btns" @click="updateEnterpriseOrderTemplate('editForm')">提交</div>
          <!-- <el-button type="primary" round @click="updateEnterpriseOrderTemplate('editForm')">提交</el-button> -->
        </div>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import { DocTemplateEditRules } from "@/utils/FormRules"
export default {
  inject: ["reload"],
  name: 'DocTemplate',
  components:
  {
    NavigationBar: () => import(/* webpackChunkName: "UploadVideo" */ '../components/NavigationBar.vue')
  },
  data() {
    return {
      tabsOptions: [{
        label: '销售单',
        name: 'SA'
      }, {
        label: '退货单',
        name: 'RG'
      }, {
        label: '退回单',
        name: 'RS'
      }],
      templateType: '',
      templateInfo: {},//模板信息
      dialogShow: false,
      editLabelType: '销售单信息',//模板编辑类型
      editForm: {
        logoImage: '',
        enterpriseName: '',
        enterpriseInfo: '',
        enterpriseNote: '',
      },
      TemplateForm: {
        logoImage: '',
        enterpriseName: '',
        enterpriseInfo: '',
        enterpriseNote: '',
      },
      FormArr: [{
        label: "企业logo：",
        prop: "logoImage",
      }, {
        label: "公司抬头：",
        prop: "enterpriseName",
        placeholder: '请输入'
      }, {
        prop: "enterpriseInfo",
        placeholder: '请输入'
      }, {
        label: "单据备注：",
        prop: "enterpriseNote",
        placeholder: '请输入'
      }],

      enterpriseLogoState: '',//公司logo（“Y”：上传公司logo “D”: 删除公司logo）
      editRules: DocTemplateEditRules,
      lastModifyDate: '',
      enterpriseStateInfo: {},//权限
      logoReplaceImg: '',
      logoImageArr: {},
    }
  },
  created() {
    this.templateType = this.$getStorage('templateType') ? this.$getStorage('templateType') : 'SA'
    this.enterpriseStateInfo = this.$getStorage('enterpriseStateInfo')
    this.getEnterpriseOrderTemplate()
  },
  methods: {
    // 获取textarea内容
    getTextAreaValue(data) {
      this.editForm[data.type] = data.value
    },
    // 获取公司抬头内容
    getValue(data) {
      this.editForm[data.type] = data.value
    },
    // 删除企业logo图片
    deleteUploadImage() {
      this.editForm.logoImage = '';
      this.enterpriseLogoState = 'D'
    },
    // 刷新当前页面
    refresh() {
      this.reload();
    },
    // 用户获取企业订单模板接口
    getEnterpriseOrderTemplate() {
      let that = this;
      that.$http.getEnterpriseOrderTemplate({
        lastModifyUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        templateType: that.templateType
      }).then(res => {
        let { templateType, enterpriseName, enterpriseInfo, enterpriseNote, lastModifyDate, enterpriseLogo } = res.data;
        that.TemplateForm = {
          enterpriseName,
          enterpriseInfo,
          enterpriseNote,
          logoImage: enterpriseLogo
        }
        that.lastModifyDate = lastModifyDate;
        that.templateType = templateType
      })
    },
    handleClick(tab) {
      if (tab.name == 'SA') {
        this.editLabelType = '销售单信息';
        this.templateType = 'SA';
      } else if (tab.name == 'RG') {
        this.editLabelType = '退货单信息'
        this.templateType = 'RG';
      } else {
        this.editLabelType = '退回单信息'
        this.templateType = 'RS';
      }
      this.$setStorage('templateType', this.templateType)
      this.getEnterpriseOrderTemplate()
    },
    handleEdit() {
      this.editForm = {
        logoImage: this.TemplateForm.logoImage,
        enterpriseName: this.TemplateForm.enterpriseName,
        enterpriseInfo: this.TemplateForm.enterpriseInfo,
        enterpriseNote: this.TemplateForm.enterpriseNote,
      }
      this.dialogShow = true;

    },
    FormData(params) {
      let that = this;
      var formData = new FormData();
      formData.append("userId", parseInt(that.$store.state.userId));
      formData.append("token", that.$getStorage("token"));
      formData.append("file", params.file);
      formData.append("source", 'pc');
      that.uploadOssOrderEnterpriseLogoFile(formData);
    },
    // // 订单模板公司logo上传接口
    uploadOssOrderEnterpriseLogoFile(formData) {
      let that = this;
      that.$http.uploadOssOrderEnterpriseLogoFile(formData).then(res => {
        if (res.code == 200) {
          that.enterpriseLogoState = 'Y';
          that.editForm.logoImage = res.data.imgSts
          that.logoImageArr = res.data
        }
      })
    },
    // 用户修改企业订单模板接口
    updateEnterpriseOrderTemplate(form) {
      let that = this,
        { templateType, enterpriseLogoState, lastModifyDate } = that,
        { enterpriseName, enterpriseNote, enterpriseInfo } = that.editForm;
      that.$refs[form].validate((valid) => {
        if (valid) {
          that.$confirm('确定修改？', '提示').then(() => {
            that.$http.updateEnterpriseOrderTemplate({
              lastModifyUserId: parseInt(that.$store.state.userId),
              token: that.$getStorage('token'),
              templateType,
              enterpriseName,
              enterpriseNote,
              enterpriseInfo,
              source: 'pc',
              enterpriseLogoState,
              enterpriseLogo: that.logoImageArr.img,
              lastModifyDate,
            }).then(res => {
              if (res.code == 200) {

                that.$common.message(res.msg, 'success');
                that.refresh()
                setTimeout(() => {
                  that.dialogShow = false
                }, 1200);
              } else if (res.code == 275) {
                that.$common.message(res.msg);
                that.refresh()
                setTimeout(() => {
                  that.dialogShow = false
                }, 1200);
              }
            })
          }).catch(() => { })
        }
      })

    },

    // 关闭弹出
    closeDialog() {
      this.dialogShow = false
    },
    back() {
      this.$router.go(-1)
      this.$removeStorage('templateType')
    }
  },
}

</script>
<style lang='less' scoped>
.DocTemplate {
  .main {
    width: 980px;

    .banner {
      background: url('../../../assets/supply/supplyBanner.png');
      height: 100px;
      color: #FFFFFF;
      font-size: 30px;
      line-height: 100px;
      text-align: center;
      margin-bottom: 40px;
    }

    .tabs {
      padding: 20px 40px;
      cursor: pointer;
    }

    .selectTabs {
      color: #333;
      font-size: 16px;
    }

    .NoselectTabs {
      color: #fff;
      font-size: 16px;
      background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
    }

    .modifyNote {
      padding: 15px 30px;
      // height: 230px;
      background: #F3F8FE;
      border-radius: 5px;
      color: #666666;
      white-space: pre-line;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }

    .btn {
      width: 160px;
      height: 50px;
      background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
      border-radius: 10px;
      color: #FFF;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
  }



  /deep/ .el-tabs__content {
    padding: 30px 15px !important;
  }

  .el-button.is-round {
    width: 50%;
  }

  .btns {
    width: 160px;
    height: 50px;
    background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
    border-radius: 10px;
    color: #FFF;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }


  .no-img {
    width: 100px;
    height: 100px;
    border: 1px dotted #999;
    border-radius: 5px;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
  }

  .temp {
    /deep/.el-dialog__header {
      padding-top: 40px !important;
      padding-left: 70px;
      color: #000000;
      font-weight: bold;

      span {
        padding-top: 20px !important;
        font-size: 37px !important;
      }
    }

    /deep/.el-form-item__label {
      font-size: 16px !important;
      font-weight: 400;
      font-weight: 500;
      color: #333333;
      line-height: 48px;
    }
  }
}
</style>
